import {
  CalendarDays,
  Copy,
  EllipsisVertical,
  Pencil,
  // SlidersHorizontal,
  Tag,
  Trash2,
} from 'lucide-react';

// import Button from '../../../../common/components/Button';
import Dropdown from '../../../../common/components/DropDown';
import HeaderComponent from '../../../../common/components/Header/';
import Input from '../../../../common/components/Input';
import { formatDate, formatList } from '../../../../utils/formatters';
import { useCampaign } from '../contexts/CampaignProvider';
import { useModal } from '../contexts/ModalProvider';
import { DeleteCampaignModal } from './DeleteCampaignModal';
import { DuplicateCampaignModal } from './DuplicateCampaignModal';
import { UpdateCampaignModal } from './UpdateCampaignModal';

const GET_CAMPAIGN_STATUS = {
  enabled: {
    title: 'Ativa',
    color: '#90E59D',
  },
  no_influencers: {
    title: 'Não há influenciadores em campanha',
    color: '#FFE088',
  },
  disabled: {
    title: 'Finalizada',
    color: '#C8C8C8',
  },
  waiting: {
    title: 'Aguardando iniciar',
    color: '#B6E8FF',
  },
  missing_hashtags_or_at: {
    title: 'Marcações ou hashtags ausentes',
    color: '#FFB3AA',
  }
};

export const Header = () => {
  const { tabs, handleTabChange, campaign, searchByText, handleSearchByText } = useCampaign();

  const { openModal } = useModal();

  const handleOpenUpdateCampaignModal = () => {
    openModal(<UpdateCampaignModal row={campaign} />);
  };

  const handleOpenDeleteCampaignModal = () => {
    openModal(<DeleteCampaignModal campaignId={campaign.uid} />);
  };

  const handleOpenDuplicateCampaignModal = () => {
    openModal(<DuplicateCampaignModal row={campaign} />);
  };

  const handleCampaignAction = (key) => {
    if (key === 'edit') handleOpenUpdateCampaignModal();
    if (key === 'delete') handleOpenDeleteCampaignModal();
    if (key === 'duplicate') handleOpenDuplicateCampaignModal();
  };

  const status = GET_CAMPAIGN_STATUS[campaign?.status ?? 'enabled'];

  const hashtags = campaign?.hashtags ?? [];

  const mentions = campaign?.mentions ?? [];

  const tags = formatList([...hashtags, ...mentions]) || 'Não há marcações ou hashtags cadastradas.';

  return (
    <>
      <HeaderComponent title={campaign?.title} />

      <ul className="campaign_details_rm">
        <li>
          Status:{' '}
          <span className="status__campaign_details_rm" style={{ backgroundColor: status.color }}>
            {status.title}
          </span>
        </li>
        <li>
          <CalendarDays /> Período: {formatDate(campaign?.start_date)} à{' '}
          {formatDate(campaign?.end_date)}
        </li>
        <li>
          <Tag /> Capturar publicações com: <b className="tags__campaign_details_rm">{tags}</b>
        </li>
        <li>
          <Dropdown
            options={[
              {
                icon: <Pencil size={20} />,
                key: 'edit',
                label: 'Editar campanha',
              },
              {
                icon: <Trash2 size={20} />,
                key: 'delete',
                label: 'Excluir campanha',
              },
              {
                icon: <Copy size={20} />,
                key: 'duplicate',
                label: 'Duplicar campanha',
              },
            ]}
            onSelect={handleCampaignAction}
          >
            <button type="button">
              <EllipsisVertical />
            </button>
          </Dropdown>
        </li>
      </ul>

      <ul className="tabs_rm">
        <li>
          <button
            data-active={tabs === 'influencers'}
            data-tab="influencers"
            onClick={handleTabChange}
          >
            Gerenciamento dos Influenciadores
          </button>
        </li>

        {/* <li>
          <button
            type="button"
            data-active={tabs === 'messages'}
            data-tab="messages"
            onClick={handleTabChange}
          >
            Central de Mensagens
          </button>
        </li> */}
        
        <li>
          <button
            type="button"
            data-active={tabs === 'reports'}
            data-tab="reports"
            onClick={handleTabChange}
          >
            Relatório de Campanha
          </button>
        </li>

        <li>
          <Input
            isOutlined
            className="input-name"
            prefix={<i className="icon icon-search" />}
            placeholder="Pesquisar por @ ou nomes"
            value={searchByText}
            onChange={handleSearchByText}
          />

          {/* <Button>
            <SlidersHorizontal />
          </Button> */}
        </li>
      </ul>
    </>
  );
};
