import React, { useEffect } from 'react';
import { useCampaign } from '../contexts/CampaignProvider';
import { useTranslation } from 'react-i18next';
import BarChart from '../../../../common/components/Charts/BarChart';
import Select from '../../../../common/components/Select';
import Tooltip from 'antd/es/tooltip';
import { ExternalLink } from 'lucide-react';
import { buildValue, formatDate, formatPercentage, humanize } from '../../../../utils/formatters';
import { Toggle } from './Toggle';
import { WorkspaceService } from '../service';
import feedback from '../../../../common/components/Feedback';
import moment from 'moment';
import { IsLoading } from '../../../../common/components/Loading';
import Connect from './Connect';
import AvatarNetwork from '../../../../common/components/AvatarNetwork';
import { useNavigate } from 'react-router-dom';
import { Tooltip as AntTooltip, Modal as AntModal } from 'antd';
import Button from '../../../../common/components/Button';
import Pagination from '../../../../common/components/Pagination';

const colorPalette = [
  '#C5E1A5', // Light Green
  '#FFCC80', // Light Orange
  '#81D4FA', // Light Blue
  '#B39DDB', // Light Purple
  '#F48FB1', // Light Pink
  '#FFB74D', // Orange
  '#4DB6AC', // Teal
  '#7986CB', // Indigo
  '#FF8A65', // Deep Orange
  '#A1887F', // Brown
  '#9CCC65', // Lime
  '#4DD0E1', // Cyan
  '#BA68C8', // Purple
  '#4FC3F7', // Light Blue (darker shade)
  '#FFF176', // Yellow
  '#64B5F6', // Blue
  '#81C784', // Green
  '#DCE775', // Lime (lighter shade)
  '#FFD54F', // Amber
  '#FF8A80', // Red (lighter shade)
  '#90A4AE', // Blue Grey
  '#F06292', // Pink (darker shade)
  '#7CB342', // Light Green (darker shade)
  '#FFA726', // Orange (darker shade)
  '#78909C', // Blue Grey (darker shade)
];

const graphMetrics = [
  {
    value: 'cost_per_view',
    key: 'Custo por Visualização (CPV)',
  },
  {
    value: 'cost_per_reach',
    key: 'Custo por Alcance (CPA)',
  },
  {
    value: 'engagement_rate',
    key: 'Taxa de Engajamento (TXE)',
  },
  {
    value: 'interactions',
    key: 'Interações',
  },
  {
    value: 'views',
    key: 'Visualizações',
  },
  {
    value: 'likes',
    key: 'Curtidas',
  },
  {
    value: 'comments',
    key: 'Comentários',
  },
  {
    value: 'reach',
    key: 'Alcance',
  },
  {
    value: 'total_media',
    key: 'Número de Publicações',
  },
];

const metricsMediaOptions = [
  {
    value: 'all',
    key: 'Geral',
  },
  {
    value: 'post',
    key: 'Posts',
  },
  {
    value: 'video',
    key: 'Vídeos',
  },
  {
    value: 'reel',
    key: 'Reels',
  },
  {
    value: 'story',
    key: 'Stories',
  },
];

const filterPosts = [
  {
    value: 'all',
    key: 'Todos',
  },
  {
    value: 'post',
    key: 'Posts',
  },
  {
    value: 'video',
    key: 'Vídeos',
  },
  {
    value: 'reel',
    key: 'Reels',
  },
  {
    value: 'story',
    key: 'Stories',
  },
];

const reportMetricsLabels = [
  {
    value: 'investment',
    label: 'Investimento',
    build: (value) => `${buildValue('currency', value)}`,
  },
  {
    value: 'totalInfluencers',
    label: 'Influenciadores',
  },
  {
    value: 'totalContent',
    label: 'Publicações',
  },
  {
    value: 'reach',
    label: 'Alcance',
    build: (value) => `${humanize(value)}`,
  },
  {
    value: 'views',
    label: 'Visualizações',
    build: (value) => `${humanize(value)}`,
  },
  {
    value: 'engagementRate',
    label: 'Engajamento',
    build: (value) => `${formatPercentage(value)}`,
  },
];
const getRandomColor = () => colorPalette[Math.floor(Math.random() * colorPalette.length)];
export const Report = () => {
  const { campaignId, campaign } = useCampaign();
  const { t } = useTranslation();
  const [metric, setMetric] = React.useState('views');
  const [metricMedia, setMetricMedia] = React.useState('all');
  const [influencers, setInfluencers] = React.useState({ status: 'loading', data: [] });
  const [reportMetrics, setReportMetrics] = React.useState({ status: 'loading', data: {} });
  const [posts, setPosts] = React.useState({
    status: 'loading',
    data: [],
    pagination: { count: 0, pages: 0, current_page: 0, items_per_page: 0 },
  });
  const [downloadFile, setDownloadFile] = React.useState({ status: 'idle' });
  const [postPage, setPostPage] = React.useState(0);
  const [postFilter, setPostFilter] = React.useState('all');
  const [postMetricsProfileId, setPostMetricsProfileId] = React.useState('all');

  const [selectedInfluencer, setSelectedInfluencer] = React.useState(null);
  const [selectedProfilePosts, setSelectedProfilePosts] = React.useState({
    status: 'loading',
    data: [],
  });
  const [selectedProfilePostType, setSelectedProfilePostType] = React.useState('all');

  const navigate = useNavigate();

  const getPosts = (p) => {
    setSelectedProfilePosts((old) => ({ ...old, status: 'loading' }));
    WorkspaceService.report
      .getPostsDataByProfile(campaignId, selectedInfluencer.id, p, selectedProfilePostType)
      .then((response) => {
        const reportPosts = response.data.items.map((item) => ({
          id: item.post_id,
          thumb: item.thumbnail,
          type: item.type,
          post_id: item.post_id,
          reach: item.counters.reach,
          views: item.counters.views,
          interactions: item.counters.interactions,
          likes: item.counters.likes,
          comments: item.counters.comments,
          permalink: item.permalink,
          engagement_rate: parseFloat((item.engagement_rate * 100).toFixed(2)),
          date: moment(item.posted_at).format('DD/MM/YYYY'),
          inCampaign: item.in_campaign,
          metrics_profile_id: item.profile,
        }));
        setSelectedProfilePosts({
          status: 'completed',
          data: reportPosts,
          pagination: {
            count: response.data.count,
            current_page: response.data.current_page,
            pages: response.data.pages,
            items_per_page: response.data.items_per_page,
          },
        });
      })
      .catch(() => {
        feedback.error('Error ao carregar dados dos posts');
      });
  };

  useEffect(() => {
    if (selectedInfluencer !== null) {
      getPosts(0);
    }
  }, [selectedInfluencer, selectedProfilePostType]);

  const buildMetricsData = () => {
    return influencers.data.map((item) => {
      console.log(item)
      if (metricMedia === 'all') {
        return {
          label: item.name,
          value: item[metric],
          color: item.color,
        };
      }

      const metricByPostType = item.post_metrics.filter((item) => item.post_type === metricMedia);
      console.log(metricMedia)
      return {
        label: item.name,
        value: metricByPostType.length > 0 ? metricByPostType[0][metric] : 0,
        color: item.color,
      };
    });
  };

  useEffect(() => {
    WorkspaceService.report
      .profileData(campaignId)
      .then((response) => {
        const reportProfiles = response.data.profiles.map((item) => ({
          color: getRandomColor(),
          id: item.data.id,
          network: item.data.network,
          name: item.data.name,
          username: item.data.username,
          avatar: item.data.picture,
          followers: item.data.counters.followers,
          story: item.story,
          video: item.video,
          reel: item.reel,
          post: item.post,
          reach: item.reach,
          total_media: item.story + item.video + item.reel + item.post,
          interactions: item.interactions,
          contract: {
            posts: item.profile_card.posts_count,
            reel: item.profile_card.reels_count,
            story: item.profile_card.stories_count,
            video: item.profile_card.videos_count,
            investment_total:
              item.profile_card.reels_price +
              item.profile_card.videos_price +
              item.profile_card.stories_price +
              item.profile_card.posts_price,
          },
          engagement_rate: parseFloat((item.engagement_rate * 100).toFixed(2)),
          connect_status: item.data.connect_status,
          views: item.views,
          post_metrics: (item.workspace_report_profiles_metrics || []).map((pMetrics) => ({
            total_media: pMetrics.total_media,
            engagement_rate: parseFloat((pMetrics.engagement_rate * 100).toFixed(2)),
            post_type: pMetrics.post_type,
            comments: pMetrics.comments,
            cost_per_comment: pMetrics.cost_per_comment,
            cost_per_like: pMetrics.cost_per_like,
            views: pMetrics.views,
            cost_per_view: pMetrics.cost_per_view,
            cost_per_reach: pMetrics.cost_per_reach,
            likes: pMetrics.likes,
            reach: pMetrics.reach,
            interactions: item.interactions,
          })),
          cost_per_view: item.cost_per_view.toFixed(2),
          cost_per_reach: item.cost_per_reach.toFixed(2),
          likes: item.likes,
          comments: item.comments,
        }));
        setReportMetrics({
          status: 'completed',
          data: {
            updatedAt: response.data.updated_at,
            investment: response.data.investment,
            totalContent: response.data.total_content,
            engagementRate: response.data.engagement_rate,
            views: response.data.views,
            reach: response.data.reach,
            totalInfluencers: response.data.profiles.length,
          },
        });
        setInfluencers({ status: 'completed', data: reportProfiles });
      })
      .catch((err) => {
        console.error(err);
        feedback.error('Error ao carregar dados do relatório');
      });
  }, []);

  useEffect(() => {
    setPosts({ ...posts, status: 'loading' });
    WorkspaceService.report
      .postsData(
        campaignId,
        postPage,
        postFilter === 'all' ? ['post', 'story', 'video', 'reel'] : [postFilter],
        postMetricsProfileId,
      )
      .then((response) => {
        const reportPosts = response.data.items.map((item) => ({
          id: item.data.post_id,
          thumb: item.data.thumbnail,
          type: item.data.type,
          post_id: item.data.post_id,
          reach: item.data.counters.reach,
          views: item.data.counters.views,
          interactions: item.data.counters.interactions,
          likes: item.data.counters.likes,
          comments: item.data.counters.comments,
          permalink: item.data.permalink,
          influencer: {
            metricsId: item.profile.data.id,
            network: item.data.network,
            name: item.profile.data.name,
            username: item.profile.data.username,
            avatar: item.profile.data.picture,
          },
          engagement_rate: parseFloat((item.data.engagement_rate * 100).toFixed(2)),
          date: moment(item.data.posted_at).format('DD/MM/YYYY'),
          inCampaign: item.data.in_campaign,
        }));
        setPosts({
          status: 'completed',
          data: reportPosts,
          pagination: {
            count: response.data.count,
            current_page: response.data.current_page,
            pages: response.data.pages,
            items_per_page: response.data.items_per_page,
          },
        });
      })
      .catch(() => {
        feedback.error('Error ao carregar dados dos posts');
      });
  }, [postPage, postFilter, postMetricsProfileId]);

  const handleDownloadFile = () => {
    setDownloadFile({ status: 'loading' });
    WorkspaceService.report
      .downloadExcel(campaignId)
      .then(() => {
        setDownloadFile({ status: 'idle' });
        feedback.success('Solicitado com sucesso, o arquivo será enviado para o seu e-mail.');
      })
      .catch(() => {
        feedback.error('Error ao baixar o arquivo');
      });
  };

  const handleToggle = (metricsProfileId, metricsPostId) => {
    WorkspaceService.report
      .togglePostData(campaignId, metricsPostId, metricsProfileId)
      .then(() => {
        const newPosts = posts.data.map((post) => {
          if (post.id === metricsPostId) {
            return { ...post, inCampaign: !post.inCampaign };
          }
          return { ...post };
        });

        setPosts({ ...posts, data: newPosts });
        feedback.success('Status alterado com sucesso');
      })
      .catch(() => {
        feedback.error('Erro ao alterar status do post');
      });
  };
  return (
    <div className="report_root">
      <div className="interaction-values">
        {reportMetrics.status !== 'loading' &&
          reportMetricsLabels.map((metric) => {
            const value = reportMetrics.data[metric.value];
            return (
              <div className="interaction" key={metric.value}>
                <p className="interaction-value">
                  <AntTooltip title={value}>
                    {metric.build ? metric.build(value) : value}
                  </AntTooltip>
                </p>

                <div className="interaction-name">{metric.label}</div>
              </div>
            );
          })}

        {reportMetrics.status !== 'loading' && (
          <div className="interaction" style={{ justifyContent: 'center' }}>
            <Button
              icon="download"
              className="outline-dark"
              loading={downloadFile.status === 'loading'}
              onClick={handleDownloadFile}
            >
              {t('Exportar Dados')}
            </Button>
          </div>
        )}
      </div>
      <div className="interaction-chart chart-posts-interval" key="chart-posts-interval">
        <div className="subtitle-filter-category">
          <p className="subtitle-part">{t('Compare os Influenciadores')}</p>
          <div>
            <Select
              style={{ width: '250px' }}
              value={metricMedia}
              placeholder={t('Selecione um tipo de mídia')}
              onChange={(value) => setMetricMedia(value)}
              options={metricsMediaOptions}
            />
            <Select
              style={{ width: '250px' }}
              value={metric}
              placeholder={t('Selecione uma métrica')}
              onChange={(value) => setMetric(value)}
              options={graphMetrics}
            />
          </div>
        </div>

        <IsLoading isLoading={influencers.status !== 'completed'}>
          {influencers.data && influencers.data.length > 0 ? (
            <BarChart formatType="number" data={buildMetricsData()} />
          ) : (
            <div className="empty-data">Ainda não existem perfis em campanha.</div>
          )}
        </IsLoading>
      </div>

      <div className="interaction-chart">
        <div className="subtitle-filter-category">
          <p className="subtitle-part">
            {t('Tabela Comparativa')} ({influencers.data ? influencers.data.length : 0})
          </p>
        </div>
        <IsLoading isLoading={influencers.status !== 'completed'}>
          <div className="influencer-table-container">
            {influencers.data && influencers.data.length > 0 ? (
              <div className="container-table">
                <table>
                  <thead>
                    <tr>
                      <th>{t('Influenciadores')}</th>
                      <th>{t('Conecte')}</th>
                      <th>{t('Seguidores')}</th>
                      <th>{t('Investimento')}</th>
                      <th>{t('Visualizações')}</th>
                      <th>{t('CPV')}</th>
                      <th>{t('Engajamento')}</th>
                      <th>{t('Stories')}</th>
                      <th>{t('Reels')}</th>
                      <th>{t('Videos')}</th>
                      <th>{t('Posts')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {influencers.data.map((influencer, index) => (
                      <tr key={index} className="influencer-row">
                        <td className="td-influencer">
                          <div className="div-flex">
                            <AvatarNetwork
                              onClick={() => navigate(`/card/${influencer.id}`)}
                              size={50}
                              networkSize={16}
                              network={influencer.network}
                              src={influencer.avatar}
                            />

                            <div className="name-username">
                              <Tooltip title={influencer.name}>
                                <span className="name">{influencer.name}</span>
                              </Tooltip>
                              <div
                                className="username hover"
                                onClick={() => setSelectedInfluencer(influencer)}
                              >
                                <span style={{ textTransform: 'none' }}>
                                  Todos os posts durante a campanha
                                </span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="td-influencer td-inlfuencer-connect">
                          <Connect
                            isConnected={influencer.connect_status === 'connected'}
                            network={influencer.network}
                          />
                        </td>
                        <td>
                          <p>{humanize(influencer.followers || 0)}</p>
                        </td>

                        <td>
                          <p>{buildValue('currency', influencer.contract.investment_total || 0)}</p>
                        </td>

                        <td>
                          <p>{humanize(influencer.views || 0)}</p>
                        </td>

                        <td>
                          <p>{buildValue('currency', influencer.cost_per_view || 0)}</p>
                        </td>

                        <td>
                          <p>{formatPercentage(influencer.engagement_rate / 100)}</p>
                        </td>
                        <td>
                          <p>
                            {influencer.story}/{influencer.contract.story}
                          </p>
                        </td>
                        <td>
                          <p>
                            {influencer.reel}/{influencer.contract.reel}
                          </p>
                        </td>
                        <td>
                          <p>
                            {influencer.video}/{influencer.contract.video}
                          </p>
                        </td>

                        <td>
                          <p>
                            {influencer.post}/{influencer.contract.posts}
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="empty-data">Ainda não existem perfis em campanha.</div>
            )}
          </div>
        </IsLoading>
      </div>

      <div className="interaction-chart">
        <div className="subtitle-filter-category">
          <p className="subtitle-part">
            {t('Publicações da Campanha')} ({posts.pagination.count})
          </p>
          <div className='influencer-filter-select'>
            <Select
              style={{ width: '260px' }}
              value={postMetricsProfileId}
              placeholder={t('Selecione um Influeciador')}
              onChange={(value) => setPostMetricsProfileId(value)}
              options={[{ key: 'Todos Influenciadores', value: 'all' }].concat(
                influencers.data.map((influ) => ({
                  key: (
                    <div style={{ display: 'flex' }}>
                      <img
                        style={{
                          borderRadius: '50%',
                          marginRight: '5px',
                          width: '25px',
                          height: '25px',
                        }}
                        src={influ.avatar}
                      />
                      {influ.name}
                    </div>
                  ),
                  value: influ.id,
                })),
              )}
            />
            <Select
              style={{ width: '250px' }}
              value={postFilter}
              placeholder={t('Selecione um tipo de mídia')}
              onChange={(value) => setPostFilter(value)}
              options={filterPosts}
            />
          </div>
        </div>
        <IsLoading isLoading={posts.status !== 'completed'}>
          {posts.data && posts.data.length > 0 ? (
            <div className="influencer-table-container">
              <div className="container-table">
                <table>
                  <thead>
                    <tr>
                      <th>{t('Conteúdo')}</th>
                      <th>{t('Influenciador')}</th>
                      <th>{t('Data')}</th>
                      <th>{t('Alcance')}</th>
                      <th>{t('Visualizações')}</th>
                      <th>{t('Interações')}</th>
                      <th>{t('Curtidas')}</th>
                      <th>{t('Comentários')}</th>
                      <th>{t('Na Campanha')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {posts.data.map((post, index) => (
                      <tr key={index} className="influencer-row">
                        <td
                          className="content-thumb-col"
                          onClick={() => window.open(post.permalink, '_blank')}
                          style={{ textAlign: 'left' }}
                        >
                          <div className="influencer-post-content">
                            <div
                              className="content-thumb"
                              style={{ backgroundImage: `url(${post.thumb})` }}
                            />

                            <ExternalLink
                              style={{ color: 'white' }}
                              size={18}
                              absoluteStrokeWidth
                            />
                          </div>
                          {post.type || 'Story'}
                        </td>
                        <td>
                          <div className="influencer-post-avatar">
                            <AvatarNetwork
                              size={50}
                              networkSize={16}
                              network={post.influencer.network}
                              src={post.influencer.avatar}
                            />
                            {post.influencer.name}
                          </div>
                        </td>
                        <td>{post.date}</td>
                        <td>{humanize(post.reach)}</td>
                        <td>{humanize(post.views)}</td>
                        <td>{humanize(post.interactions)}</td>
                        <td>{humanize(post.likes)}</td>
                        <td>{humanize(post.comments)}</td>
                        <td>
                          <Toggle
                            handleToggle={() => handleToggle(post.influencer.metricsId, post.id)}
                            initialState={post.inCampaign}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="empty-data">Ainda não existem posts na campanha.</div>
          )}
        </IsLoading>

        <Pagination
          pageSize={posts.pagination.items_per_page}
          current={posts.pagination.current_page + 1}
          total={posts.pagination.count}
          isDisabled={false}
          onChange={(page) => {
            setPostPage(page - 1);
          }}
        />
      </div>

      <AntModal
        className="modal-influencer-summary"
        footer={false}
        open={selectedInfluencer !== null}
        onCancel={() => setSelectedInfluencer(null)}
      >
        {selectedInfluencer && (
          <div className="influencer" style={{ justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              <AvatarNetwork src={selectedInfluencer.avatar} network={selectedInfluencer.network} />

              <div className="name-username">
                <span className="name">{selectedInfluencer.name}</span>

                <span className="username hover" role="button">
                  @{selectedInfluencer.username}
                </span>
              </div>
            </div>

            <div style={{ color: 'white' }}>
              Posts do período de campanha: {formatDate(campaign.start_date)} à{' '}
              {formatDate(campaign.end_date)}{' '}
            </div>

            <div className="post-filter">
              <Select
                style={{ width: '250px' }}
                value={selectedProfilePostType}
                placeholder={t('Selecione um tipo de mídia')}
                onChange={(value) => setSelectedProfilePostType(value)}
                options={filterPosts}
              />
            </div>
          </div>
        )}

        <IsLoading isLoading={selectedProfilePosts.status !== 'completed'}>
          <div className="box-summary" style={{ height: 'auto' }}>
            <div className="posts scroll-posts" style={{ height: 'auto' }}>
              {selectedProfilePosts.data && selectedProfilePosts.data.length > 0 && (
                <>
                  {selectedProfilePosts.data.map((post) => {
                    const igPostValues = [
                      {
                        icon: 'eye',
                        tooltip: t('Visualizações'),
                        value: humanize(post.views),
                      },
                      {
                        icon: 'outline-like-heart',
                        tooltip: t('Curtidas'),
                        value: humanize(post.likes),
                      },
                      {
                        icon: 'outline-comment',
                        tooltip: t('Comentários'),
                        value: humanize(post.comments),
                      },
                      {
                        icon: 'clock-outline',
                        tooltip: t('Data da postagem'),
                        value: post.date,
                      },
                    ];

                    return (
                      <div
                        key={post.id}
                        className="post post-heigher"
                        style={{
                          backgroundImage: `url(${post.thumb})`,
                        }}
                      >
                        <div className="post-type">{post.type}</div>
                        <div className="add-in-campaign-toggle">
                          <Tooltip title={t('Adicionar à campanha')}>
                            <Toggle
                              handleToggle={() => handleToggle(post.metrics_profile_id, post.id)}
                              initialState={post.inCampaign}
                            />
                          </Tooltip>
                        </div>

                        <div className="gradient" />

                        <div className="post-interactions">
                          {igPostValues.map((item) => (
                            <p key={item.icon} className="interaction-data">
                              <i className={`icon icon-${item.icon}`} />
                              <span className="interaction-value">{item.value}</span>
                            </p>
                          ))}
                        </div>
                      </div>
                    );
                  })}
                </>
              )}

              {selectedProfilePosts.status === 'completed' &&
                selectedProfilePosts.pagination.count < 1 && (
                  <p className="text-empty">{t('Nenhuma publicação')}</p>
                )}
            </div>
          </div>
        </IsLoading>
        {selectedProfilePosts.pagination && selectedProfilePosts.pagination.count > 0 && (
          <Pagination
            pageSize={selectedProfilePosts.pagination.items_per_page}
            current={selectedProfilePosts.pagination.current_page + 1}
            total={selectedProfilePosts.pagination.count}
            isDisabled={false}
            onChange={(page) => {
              getPosts(page - 1);
            }}
          />
        )}
      </AntModal>
    </div>
  );
};
