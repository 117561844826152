import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import Radio from '../../../common/components/Radio';
import Input from '../../../common/components/Input';
import Button from '../../../common/components/Button';
import Spinner from '../../../common/components/Spinner';

const InfluencerContact = ({ onCompleted, selectedChat, isLoading }) => {
  const [selected, setSelected] = useState(null);
  const [radioValue, setRadioValue] = useState(null);

  let options = selectedChat.data.biography_data.emails;

  if (selectedChat.data.biography_data.email) {
    options = [...options, selectedChat.data.biography_data.email];
  }
  options = [...new Set([...options, selectedChat.data.biography_data.email, 'Outro e-mail'])].filter(Boolean);


  return (
    <div className="influencer-contact">
      <div className="influencer-contact-box">
        {options.length > 1 && (
          <>
            <p>
              Escolha uma opções de e-mail, abaixo, para iniciar uma conversa com o influenciador:
            </p>
            <Radio
              value={radioValue}
              options={options.map((item) => ({ label: item, value: item }))}
              onChange={(value) => {
                setRadioValue(value);
                if (value.includes('Outro')) {
                  setSelected(null);
                } else {
                  setSelected(value);
                }
              }}
            />
          </>
        )}

        {
            options.length === 1 && (
              <p>
                Digite o e-mail do influenciador abaixo para iniciar uma conversa:
              </p>
            )
  
        }
        {(options.length === 1 || (radioValue && radioValue.includes('Outro'))) && (
          <Input placeholder="exemplo@gmail.com" onBlur={(event) => setSelected(event.target.value)} />
        )}
        <br />
        <Button className="create-chat" isDisabled={!selected || isLoading} onClick={() => onCompleted(selected)}>
          {
            isLoading && <Spinner size={18} />
          }
          Salvar
        </Button>
      </div>
    </div>
  );
};

InfluencerContact.propTypes = {
  onCompleted: PropTypes.func.isRequired,
  selectedChat: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default InfluencerContact;
