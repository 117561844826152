import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import "./styles.scss"
import Spinner from '../Spinner';

export const TextArea = ({ placeholder, initialValue, onChange, onBlur, onSend, isLoading }) => {
  const [newMessage, setNewMessage] = useState(initialValue || '');
  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [newMessage]);

  const handleChange = (e) => {
    setNewMessage(e.target.value);
    if (onChange) onChange(e.target.value);
  };
  
  return (
    <div className="air-textarea">
      <textarea
        ref={textareaRef}
        value={newMessage}
        onChange={handleChange}
        onBlur={onBlur}
        placeholder={placeholder}
        rows={2}
      />
      <button disabled={isLoading} onClick={() => onSend(newMessage, () => setNewMessage(""))}>
        {
          isLoading ? (
            <Spinner size={18} />
          ) : (
            <i className="icon icon-send" />
          )
        }
        </button>
    </div>
  );
};

TextArea.propTypes = {
  placeholder: PropTypes.string,
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onSend: PropTypes.func,
  isLoading: PropTypes.bool,
};
