import React from 'react';
import PropTypes from "prop-types"
import './styles.scss';

const Spinner = ({ size }) => {
  const style = size ? { '--size': `${size}px` } : {};
  
  return <div className="spinner" style={style}></div>;
};

Spinner.propTypes = {
  size: PropTypes.number,
};

export default Spinner;